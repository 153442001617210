// title: Lucky Paper Radio — Pack 1, Pick 1 Cubes
// frameCardName: Cream of the Crop
// frameCardSet: mor

import * as styles from './cubes.module.scss'

import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

interface Props {
  pageContext: any
  data: {
    allMdx: {
      podcasts: {
        id: string
        frontmatter: {
          title: string
          date: string
          excerpt: string
          episodeSlug: string
          episodeNumber?: number
          episodeOrder: number
          cubes: {
            name: string
            designer?: string
            link?: string
          }[]
        }
      }[]
    }
  }
}

const PodcastPage: React.FC<Props> = (props) => {
  const { podcasts } = props.data.allMdx

  const sortedEpisodes = useMemo(() => {
    // Gatsby's sorting seems to not work completely consistently
    return sortBy(
      podcasts.filter((episode) => episode.frontmatter.cubes != null),
      (episode) => episode.frontmatter.episodeOrder,
    ).reverse()
  }, [podcasts])

  return (
    <Layout
      {...props.pageContext}
      accentColor="#57ADC9"
      includeNewsletterSignup
    >
      <section className={styles.container}>
        <Typography.PrimaryHeading>
          Pack 1, Pick 1 Cubes
        </Typography.PrimaryHeading>

        <ul className={styles.episodes}>
          {sortedEpisodes.map((episode) => (
            <li key={episode.id} className={styles.episode}>
              <Link
                className={styles.episodeName}
                to={`/podcast/${episode.frontmatter.episodeSlug}`}
              >
                {episode.frontmatter.episodeNumber ?? 'Bonus'}:{' '}
                {episode.frontmatter.title}
              </Link>

              <ul className={styles.cubes}>
                {episode.frontmatter.cubes.map((cube, index) => (
                  <li key={index} className={styles.cube}>
                    {cube.designer && <>{cube.designer}&rsquo;s </>}
                    {cube.link ? (
                      <a href={cube.link} className={styles.cubeLink}>
                        {cube.name}
                      </a>
                    ) : (
                      cube.name
                    )}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      filter: { fields: { type: { eq: "podcast-notes" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      podcasts: nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          excerpt
          episodeSlug
          episodeNumber
          episodeOrder
          cubes {
            name
            designer
            link
          }
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Cubes Discussed - Lucky Paper Radio',
        description:
          'Listener submitted, pack 1, pick 1 cubes featured on Lucky Paper Radio',
        ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
      }}
    />
  )
}

export default PodcastPage
